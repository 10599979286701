//import { Link } from "gatsby"
//import PropTypes from "prop-types"
import React from "react"
import { Link } from "gatsby"
import cm from "./Pagination.module.scss"
import range from "lodash/range"

interface Props {
    currentPage: number;
    pageCount: number;
    url: string | ((page: number) => string);
}

function paginate(currentPage: number, pageCount: number, maxDisplayedPages: number = 8): (number | string)[] {
    if (pageCount < maxDisplayedPages) {
        return range(1, pageCount + 1);
    }
    const neighborCount = Math.floor((maxDisplayedPages - 3) / 2);
    const currentPageNeighbors = range(Math.max(1, currentPage - neighborCount), Math.min(currentPage + neighborCount, pageCount) + 1);
    const pages: (number | string)[] = [];
    if (currentPageNeighbors[0] > 1) {
        pages.push(1);
    }
    if (currentPageNeighbors[0] > 2) {
        pages.push("...");
    }
    pages.push(...currentPageNeighbors);
    if (currentPageNeighbors[currentPageNeighbors.length - 1] < pageCount - 1) {
        pages.push("...");
    }
    if (currentPageNeighbors[currentPageNeighbors.length - 1] < pageCount) {
        pages.push(pageCount);
    }
    return pages;
}

const Pagination: React.FC<Props> = ({ currentPage, pageCount, url }) => {
    function getUrl(page: number): string {
        if (typeof url === "string") {
            const separator = url[url.length - 1] === "/"
                ? ""
                : "/";
            return `${url}${separator}${page.toFixed(0)}`;
        }
        
        return url(page);
    }
    
    return (
        <div className={cm.pagination}>
            <div className="row collapse">
                <div className="columns">
                    <ul className={cm.navlist}>
                        {currentPage > 1 && <li><Link to={getUrl(currentPage - 1)} className={cm.prev} title="előző oldal" >Prev</Link></li>}
                        {paginate(currentPage, pageCount).map((page, i) =>
                            typeof page === "number"
                                ? <li key={page}><Link to={getUrl(page)} className={page === currentPage ? cm.active : ""} >{page}</Link></li>
                                : <li key={`sep-${i}`}><span>...</span></li>
                            )}
                        {currentPage < pageCount && <li><Link to={getUrl(currentPage + 1)} className={`${cm.next} ${cm.disabled}`} title="következő oldal" >Next</Link></li>}
                    </ul>
                </div>
            </div>
        </div>
    )
}

export default Pagination
